import baseColors from '@elegantstack/flow-ui-theme/src/theme/colors'

//Using tailwind preset colors
import colorPreset from '@elegantstack/flow-ui-theme/src/theme/color-preset'

const colors = {
  ...baseColors,
  alphaLighter: '#d0d9e6',
  alphaLight: '#fde2c7',
  alpha: '#1d7a4a',
  alphaDark: '#b83248',
  alphaDarker: '#134080'
}

export default colors