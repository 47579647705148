import baseText from '@elegantstack/flow-ui-theme/src/theme/text'


const text = {
  ...baseText,
  h2: {
    ...baseText,
    fontSize: 4
  }
}

export default text